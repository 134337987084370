import BaseApi from './BaseApi'

export default class TokenAllApi extends BaseApi {
  constructor (type = 0) {
    super('TokenAll', type)
  }

  async getBySmallEndian (tokenCodeSe: string, fields: Array<string> | null = null) {
    const query: any = {
      type: this.type,
      select: { DataCategories: ['BASIC'], Fields: ['Description', 'MSPDescription', 'MSPIssuer', 'PrintedNumber'] },
      filter: { CodeSmallEndian: tokenCodeSe }
    }
    if (fields) {
      query.select.fields = fields
    }
    try {
      const dt = await this.filter(query.filter, { pagesize: 10, pagenumber: 1 }, query.select)
      return dt
    } catch (error) {
      console.error(error)
      console.error(this.className, query)
    }
  }

  async myTokens (id = '') {
    let filtered = ''
    if (id.length > 0) {
      filtered = `?directonly=true&relid=${id} `
    }
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/list${filtered}`
    }
    return await this.getApi(options)
  }
}
