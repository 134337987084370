import TokenAllApi from '@/services/TokenAllApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { CreateElement, VNode } from 'vue/types/umd'
import {
  VAutocomplete,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VSpacer
} from 'vuetify/lib'
import BcBtn from '../_base/BcBtn'
import ChargePointApi, { RemoteStartProps } from '../../services/ChargePointApi'

@Component
export default class RemoteStart extends Vue {
  @Prop(Object) readonly cp: any
  @Prop({ type: Boolean, default: false }) readonly isOnly: any
  @Prop({ type: String, default: '' }) readonly placementId?: string | number
  @Prop(Object) readonly connector: any

  loading = false
  tokens: Array<any> = []
  selectedToken: any = null
  showSynced = false
  status = ''
  message = ''

  render(h: CreateElement) {
    return h(
      VDialog,
      {
        scopedSlots: {
          activator: this.$scopedSlots.activator
        },
        props: {
          value: this.showSynced,
          width: 800
        },
        on: {
          input: (val: boolean) => {
            // console.log('input? ', val)
            this.showSynced = val
          }
        }
      },
      [
        h(VCard, [
          h(VCardTitle, ['Remote Start']),
          h(VCardText, this.genCardContent),
          this.actions
        ])
      ]
    )
  }

  mounted() {
    this.fetchTokens()
  }

  async fetchTokens() {
    const dt = (await new TokenAllApi().myTokens()) as any
    this.tokens = dt.data
  }

  get actions() {
    const list: Array<any> = [this.$createElement(VSpacer)]

    if (this.status === 'success') {
      list.push(
        this.$createElement('span', { props: { color: 'success' } }, [
          'Command was send succesfully'
        ])
      )
    } else if (this.status === 'error') {
      list.push(
        this.$createElement('span', { props: { color: 'error' } }, [
          'Error occured while sending the command'
        ])
      )
    } else {
      list.push(
        this.$createElement(
          BcBtn,
          {
            on: {
              click: this.start
            },
            attrs: {
              loading: this.loading,
              disabled: this.disabledState
            }
          },
          'Start'
        )
      )
    }
    list.push(
      this.$createElement(
        BcBtn,
        {
          on: {
            click: () => {
              this.showSynced = false
            }
          }
        },
        ['Close']
      )
    )
    return this.$createElement(VCardActions, list)
  }

  get disabledState() {
    if (this.selectedToken) {
      return false
    }
    return true
  }

  async start() {
    /* console.log('start :')
    console.log(this.isOnly)
    console.log(this.connector)
    console.log('[CHARGEPOINT DATA :]')
    console.log(this.cp)
    console.log('--------') */
    let props: RemoteStartProps = {
      tokenid: this.selectedToken.id,
      chargepointid: this.cp.Id
    }
    if (this.isOnly) {
      /* console.log('IN IF')
      console.log(this.connector) */
      props = {
        tokenid: this.selectedToken.id,
        connectorid: this.connector.Id
      }
      /* console.log('props')
      console.log(props) */
    } else {
      if (this.placementId) {
        props.placementid = this.placementId
        delete props.chargepointid
      }
    }

    this.loading = true
    console.log(props)
    try {
      const dt = (await new ChargePointApi().remoteStart(props)) as any
      // console.log(dt)
      this.loading = false
      if (dt.result.status === 'success') {
        this.status = 'success'
        this.message = 'Session start has been send to the charger'
      } else {
        this.status = 'error'
        this.message = 'Error has occurred while proccessing the command'
      }
    } catch (error) {
      this.status = 'error'
      this.message = 'Error has occurred while proccessing the command'
      this.loading = false
    }
  }

  get genCardContent() {
    const rtn: Array<VNode> = []
    if (!this.loading) {
      rtn.push(
        this.$createElement(VAutocomplete, {
          props: {
            label: 'Select a Charge card',
            items: this.tokens,
            value: this.selectedToken,
            'return-object': true,
            'item-text': 'printednr'
          },
          on: {
            input: (val: any) => {
              this.selectedToken = val
            }
          },
          scopedSlots: {
            item: (data: any) => {
              return this.$createElement(VListItemContent, [
                this.$createElement(VListItemTitle, [data.item.printednr]),
                this.$createElement(VListItemSubtitle, [
                  `${data.item.relationcontact.fullname}`
                ])
              ])
            }
          }
        })
      )
    }
    return rtn
  }

  @Watch('showSynced')
  showSyncedOnChanged(val: any, oldVal: any) {
    if (!oldVal && val) {
      this.status = ''
    }
  }
}
