import TokenAllApi from '@/services/TokenAllApi'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit, Ref, PropSync } from 'vue-property-decorator'
import { CreateElement, VNode } from 'vue/types/umd'
import { VBtn, VIcon, VDialog, VCard, VCardTitle, VCardText, VAutocomplete, VListItemContent, VListItemTitle, VListItemSubtitle, VCardActions, VSpacer } from 'vuetify/lib'
import BcBtn from '../_base/BcBtn'
import ChargePointApi, { IReset } from '../../services/ChargePointApi'

@Component
export default class Reset extends Vue {
  @Prop(Object) readonly cp: any

  loading = false
  tokens: Array<any> = []
  selectedToken: any = null
  showSynced = false
  status = ''

  render (h: CreateElement) {
    return h(VDialog, {
      scopedSlots: {
        activator: this.$scopedSlots.activator
      },
      props: {
        value: this.showSynced,
        width: 800
      },
      on: {
        input: (val: boolean) => { this.showSynced = val }
      }
    }, [
      h(VCard, [
        h(VCardTitle, ['Reset']),
        h(VCardText, ['Warning Resets are preformed on actual chargepoints, only reset the device if there is no charge session active']),
        this.actions
      ])
    ])
  }

  mounted () {
    this.fetchTokens()
  }

  async fetchTokens () {
    const dt = await new TokenAllApi().myTokens() as any
    this.tokens = dt.data
  }

  get actions () {
    const success = this.$createElement('span', { props: { color: 'success' } }, ['Command was send succesfully'])
    const error = this.$createElement('span', { props: { color: 'error' } }, ['Error occured while sending the command'])
    /* const soft = this.$createElement(BcBtn, {
      attrs: { loading: this.loading },
      on: { click: () => this.doReset('SOFT') }
    }, 'Soft Reset') */
    const hard = this.$createElement(BcBtn, {
      attrs: { loading: this.loading },
      on: { click: () => this.doReset('HARD') }
    }, 'Ok')
    const close = this.$createElement(BcBtn, {
      on: { click: () => { this.showSynced = false } }
    }, 'Close')
    const btns = [this.$createElement(VSpacer)]
    if (this.status === 'success') {
      btns.push(success)
    } else if (this.status === 'error') {
      btns.push(error)
    } else {
      // btns.push(soft)
      btns.push(hard)
    }
    btns.push(close)
    return this.$createElement(VCardActions, btns)
  }

  async doReset (type: 'HARD' | 'SOFT') {
    // console.log('CHARGEPOINT : ')
    // console.log(this.cp)
    // Placements is not used anymore
    // if (this.cp.Placements.length > 0) {
    const data: IReset = {
      mode: type,
      chargepointid: this.cp.Id
      // placementid: this.cp.Placements[0].Id
    }
    this.loading = true
    const dt = await new ChargePointApi().reset(data) as any
    this.loading = false
    if (dt.Data) {
      if (dt.result.status === 'Success') {
        this.status = 'success'
        setTimeout(() => {
          this.showSynced = false
        }, 5000)
      } else {
        this.status = 'error'
      }
    }
    // }
  }

  @Watch('showSynced')
  showSyncedOnChanged (val: any, oldVal: any) {
    // console.log(`val: ${val}`, `oldVal: ${oldVal}`)
    if (!oldVal && val) {
      this.status = ''
    }
  }
}
