import BaseApi from './BaseApi'

export default class MapApi extends BaseApi {
  constructor (type = 0) {
    super('Subscription', type)
  }

  /* async mySubscriptions (CusId: number|string) {
    const filter = { CusBlueId: CusId }
    const dt = <any> await this.filter(filter, { pagenumber: 1, pagesize: 200 })
    return dt.Data
  } */
  async getSubscriptions () {
    // {{Protocol}}://{{BaseUrl}}/{{AppUrl}}/{{Version}}/subscription/:Uci/list?pagesize=10&type=0
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/subscription/BCO/list`,
      method: 'get',
      params: {
        type: 0,
        cusId: 4812,
        pagesize: 1000,
        pagenumber: -1
      }
    }
    return await this.getApi(options)
  }

  async details (id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/details/${id}`,
      method: 'get'
    }
    return await this.getApi(options)
  }

  async list (minLng: any, minLat: any, maxLng: any, maxLat: any, roaming: any, mychargers: any, BCChargers: any, zoom: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/maps/BCO/clusterlist`,
      method: 'get',
      params: {
        MinLng: minLng,
        MinLat: minLat,
        MaxLng: maxLng,
        MaxLat: maxLat,
        Roaming: roaming,
        MyChargers: mychargers,
        BCChargers: BCChargers,
        zoom: zoom
      }
    }
    return await this.getApi(options)
  }

  async detail (id: any, lat: any, lng: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/maps/BCO/${id}/${lat}/${lng}`,
      method: 'get'
    }
    return await this.getApi(options)
  }

  async getMap () {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/locations/1.2/BCO/maplist/public?countries=["BE"]&roaming=0`,
      method: 'get'
    }
    // console.log(options)
    return await this.getApi(options)
  }

  async activate (id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/activate/${id}`,
      method: 'put'
    }
    return await this.putApi(options)
  }

  async createUpdate (payload: any) {
    // console.log(payload)
    const opts: any = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/chargelocation/BCO/createupdate`,
      method: 'post',
      data: payload
    }
    return await this.postApi(opts)
  }

  async deactivate (id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/deactivate/${id}`,
      method: 'put'
    }
    return await this.putApi(options)
  }

  async addCard (payload: any) {
    // console.log(payload)
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/link`,
      method: 'post'
    }
    return await this.postApi(options)
  }
}
